import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
  const [meetingId, setMeetingId] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{
   window.location.href = 'https://zoom.us/';
  },[])
  const handleJoinMeeting = (e) => {
    e.preventDefault();
    if (meetingId && userName) {
      navigate(`/meeting/${meetingId}`);
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Join a Zoom Meeting</h1>
      {/* <form onSubmit={handleJoinMeeting}> */}
      {/*   <div> */}
      {/*     <label> */}
      {/*       Meeting ID: */}
      {/*       <input */}
      {/*         type="text" */}
      {/*         value={meetingId} */}
      {/*         onChange={(e) => setMeetingId(e.target.value)} */}
      {/*         required */}
      {/*       /> */}
      {/*     </label> */}
      {/*   </div> */}
      {/*   <div> */}
      {/*     <label> */}
      {/*       Name: */}
      {/*       <input */}
      {/*         type="text" */}
      {/*         value={userName} */}
      {/*         onChange={(e) => setUserName(e.target.value)} */}
      {/*         required */}
      {/*       /> */}
      {/*     </label> */}
      {/*   </div> */}
      {/*   <button type="submit">Join Meeting</button> */}
      {/* </form> */}
    </div>
  );
};

export default MainPage;
