import React, { useState } from 'react';
import './ZoomMeetingPage.css';
import ErrorModal from './ErrorModal';

const ZoomMeetingPage = () => {
  const [name, setName] = useState('');
  const [cameraError, setCameraError] = useState(false); // Simulate camera error for demo purposes
  const [participants, setParticipants] = useState([]); // To simulate the current participants
  const [showErrorModal, setShowErrorModal] = useState(false);

  const maxParticipants = 0;

  const handleJoin = (e) => {
    e.preventDefault();
    if (participants.length >= maxParticipants) {
      setShowErrorModal(true);
      return;
    }
    setParticipants([...participants, name]);
    setName('');
  };

  const handleCloseModal = () => {
    setShowErrorModal(false);
  };

  const handleRetry = () => {
    setShowErrorModal(false);
    // Additional retry logic can be added here
  };


  return (
    <div className="zoom-page">
      <div className="back-button">
        <a href="#"><svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="btn-back-pwa-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.635 8.339a.498.498 0 0 0 .037.037l3.978 3.978a.5.5 0 1 0 .706-.708L6.715 8.004l3.639-3.65a.501.501 0 0 0 0-.708.499.499 0 0 0-.707 0L5.731 7.575a.502.502 0 0 0-.096.764Z" fill="currentColor"></path></svg>Back</a>
      </div>
      <div className="meeting-container">
      
        <div className="video-preview">
          {cameraError && (
            <div className="camera-error">
              <p>Cannot detect your camera, please check the device and connection and try again.</p>
            </div>
          )}
          <div className="video-placeholder">
            <img src="/avatar.png" alt="User" />
            <div className="controls">
              <img src="/audio.png" alt="User"  />
              <img src="/video.png" alt="User"  />
            </div>
          </div>
        </div>
        <div className="meeting-info">
          <div style={{textAlign:"center", padding:"20px"}}><h2 style = {{color:'black'}}>Enter Meeting Info</h2></div>
          <form onSubmit={handleJoin}>
            <label style = {{color:'black'}}>
              Your Name <br/>
              <input
              style = {{width: "100%"}}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                required
              />
            </label>
            <label>
              <input type="checkbox" />
              Remember my name for future meetings
            </label>
            <p style={{fontSize: "13px", color:"black"}}>
              By clicking "Join", you agree to our <a href="https://app.zoom.us/terms">Terms of Service</a> and <a href="https://explore.zoom.us/en/privacy/">Privacy Statement</a>.
            </p>
            <button type="submit" disabled={!name}>Join</button>
          </form>
        </div>
      </div>
      <footer>
        <p>© 2024 Zoom Video Communications, Inc. All rights reserved. <a href="https://explore.zoom.us/en/privacy/">Privacy & Legal Policies</a> | <a href="#">Send Report</a></p>
      </footer>
      <ErrorModal show={showErrorModal} handleClose={handleCloseModal} handleRetry={handleRetry} />
    </div>
  );
};

export default ZoomMeetingPage;
