import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ show, handleClose, handleRetry }) => {
  if (!show) return null;

  return (
    <div className="error-modal-overlay">
      <div className="error-modal-content">
        <h2>Meeting Capacity Reached</h2>
        <p>This meeting has reached the maximum number of participants allowed. Please contact the meeting host for assistance or try joining again later.</p>
        <div className="error-modal-actions">
          <button onClick={handleRetry} className="retry-button">Retry</button>
          <button onClick={handleClose} className="ok-button">OK</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
