import React, { useState } from 'react';
import './CookieModal.css';

const CookieModal = ({ isOpen, onClose }) => {
  const [expanded, setExpanded] = useState({
    targeting: false,
    functional: false,
    performance: false,
    strictly: false
  });

  const toggleExpanded = (category) => {
    setExpanded(prevState => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <img src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg" alt="Zoom Logo" className="modal-logo" />
        <h3>Cookie Preference Center</h3>
        <p>Cookies and other similar technologies (“Cookies”) are important to the proper functioning of a site and to provide visitors with a seamless and customized experience. Zoom uses Cookies to enable you to use our site. We also use cookies to enable you to personalize your use of our site, to provide you enhanced functionality and to continuously improve the performance of our site. If you have Targeting cookies enabled below and depending on your account type or login state, we may allow third-party advertisers to show you advertising relevant to you on our website or products, using their Cookies on our site.</p>
        <p>You can accept or decline all but Strictly Necessary Cookies, or customize your cookie settings below. You can change your cookie settings at any time. Some Strictly Necessary Cookies may transfer personal data to the United States. To learn more about how Zoom processes personal data, please visit our <a href="https://explore.zoom.us/en/privacy/">privacy statement.</a></p>
        <p>For California Residents, you may exercise your right to “Opt-Out of the Sale of Personal Information” by toggling the button labeled “Targeting” below to off.<br/><a href="https://explore.zoom.us/en/cookie-policy/">More information</a></p>
        <h3>Manage Consent Preferences</h3>
        <div className="cookie-preferences">
          <div className="cookie-category" onClick={() => toggleExpanded('targeting')} >
            <button className="toggle-button" >
              {expanded.targeting ? '-' : '+'}
            </button>
            <span>Targeting Cookies</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
          {expanded.targeting && (
            <div className="cookie-details">
              <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to track your usage of our website and, depending on their policies, may combine that information with other information to show you relevant advertisements on our site and other sites. If you do not allow these cookies, you will not see personalized advertising on Zoom’s website or products.
              <br/>
              <a href="#">Cookies Details</a>
              </p>
              
            </div>
          )}
          <div className="cookie-category" onClick={() => toggleExpanded('functional')}>
            <button className="toggle-button" >
              {expanded.functional ? '-' : '+'}
            </button>
            <span>Functional Cookies</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
          {expanded.functional && (
            <div className="cookie-details">
              <p>These cookies enable the website to provide enhanced functionality and customization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.
              <br/>
              <a href="#">Cookies Details</a>
              </p>
            </div>
          )}
          <div className="cookie-category" onClick={() => toggleExpanded('performance')}>
            <button className="toggle-button" >
              {expanded.performance ? '-' : '+'}
            </button>
            <span>Performance Cookies</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
          {expanded.performance && (
            <div className="cookie-details">
              <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.
              <br/>
              <a href="#">Cookies Details</a>
              </p>
            </div>
          )}
          <div className="cookie-category" onClick={() => toggleExpanded('strictly')} >
            <button className="toggle-button" >
              {expanded.strictly ? '-' : '+'}
            </button>
            <span>Strictly Necessary Cookies</span>
            <span className="always-active">Always Active</span>
          </div>
          {expanded.strictly && (
            <div className="cookie-details">
              <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.
              <br/>
              <a href="#">Cookies Details</a>
              </p>
            </div>
          )}
        </div>
        <button className="confirm-button" onClick={onClose}>Confirm My Choices</button>
      </div>
    </div>
  );
};

export default CookieModal;
