import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import MeetingPage from './MeetingPage';
import ZoomPage from './ZoomPage';
import ZoomMeetingPage from './ZoomMeetingPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/zoom/:meetingId" element={<MeetingPage />} />
        <Route path="/j/:meetingId" element={<ZoomPage />} />
        <Route path="/wc/:meetingId/join" element={<ZoomMeetingPage />} />

      </Routes>
    </Router>
  );
};

export default App;
