import React from 'react';
import { useParams } from 'react-router-dom';

const MeetingPage = () => {
  const { meetingId } = useParams();

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Meeting ID: {meetingId}</h1>
      <p>Welcome to the Zoom meeting!</p>
      {/* Here you would integrate your actual meeting component or functionality */}
    </div>
  );
};

export default MeetingPage;
